import React from 'react'
import { Link } from 'gatsby'
import styles from './navigation.module.css'

const pages = [
  { href: '/',            text: 'Home' },
  { href: '/portfolio/',  text: 'Portfolio' },
  { href: '/contact-us/', text: 'Contact Us' },
];

export default () => (
  <nav role="navigation" className={styles.navigation}>
    {pages.map(a => <Link to={a.href} key={a.href}>{a.text}</Link>)}
  </nav>
)
