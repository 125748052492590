import Container from './container'
import Header from './header'
import Helmet from 'react-helmet'
import React, { Fragment } from 'react'
import { siteMetadata } from "../hooks/site-metadata"

import reset from './reset.css'
import common from './common.css'
import base from './base.css'

export default ({ children }) => {
  const { title } = siteMetadata()

  return (
    <Fragment>
      <Helmet title={title} />
      <Header />
      <div id="page">
        <Container>
          {children}
        </Container>
      </div>
    </Fragment>
  )
}
